import React, { useState } from 'react';
import './login-view.scss';
import { PRODUCT_IMAGE, usePickExpApi } from '@pickexp/core';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

const LoginView = (props) => {
  const { inSignUp } = props;
  const api = usePickExpApi();

  const {
    signIn: loginUrl,
    signUp: signUpUrl,
    verifyTwoFactor: verifyTwoFactorUrl,
  } = api.getCoreApiURLs();

  const { APP_ID } = useParams();
  const [errorMessage, setErrorMessage] = useState();
  const [useTwoFactor, setUseTwoFactor] = useState(false);
  const [accountEmail, setAccountEmail] = useState('');
  const [redirectURL, setRedirectURL] = useState();
  const [isLogIn, setIsLogIn] = useState(inSignUp ? false : true);
  const [isSignUp, setIsSignUp] = useState(inSignUp ? true : false);

  const shaPW = ({ username, email, password }) => {
    const user = username || email;
    const nbytes = user.length / 2;
    let fbytes = '';
    let lbytes = '';
    for (var i = 0; i < user.length; ++i) {
      const code = user.charCodeAt(i);
      if (nbytes < i) {
        fbytes += code;
      } else {
        lbytes += code;
      }
    }
    const hashDigest = sha256(`${fbytes}${password}${lbytes}`);
    return Base64.stringify(hashDigest);
  };

  const initialLoginValues = { username: '', password: '' };
  const initialSignUpValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  };

  const validateSignUp = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const onSubmit = async (values, { setSubmitting }) => {
    if (localStorage.getItem('stk')) {
      localStorage.removeItem('stk');
    }
    setErrorMessage('');

    const requestData = {
      ...values,
      password: shaPW(values),
      product: APP_ID,
      platform: 'webapp',
    };

    const doSignIn = async ({ signInRequestData }) => {
      const {
        data: { isTwoFactor, token, url },
      } = await api.fetch.post(loginUrl, signInRequestData);
      token && localStorage.setItem('stk', token);
      if (token && url) {
        if (isTwoFactor) {
          setUseTwoFactor(true);
          setRedirectURL(`${url}/checkIn?stk=${token}`);
          setAccountEmail(requestData.username);
        } else {
          window.location.href = `${url}/checkIn?stk=${token}`;
        }
      } else {
        //'Invalid user or password.'
      }
    };

    if (isSignUp) {
      try {
        const {
          data: { status },
        } = await api.fetch.post(signUpUrl, requestData);
        if (status === 'Success') {
          setIsSignUp(false);
          setIsLogIn(true);
        }
      } catch (error) {
        if (error.error.message) {
          if (error.error.message === 'Network Error') {
            setErrorMessage('Network Error, Try again later');
          }
        }
      }
    } else {
      doSignIn({ signInRequestData: requestData });
    }

    setSubmitting(false);
  };

  const onTwoFactorCodeSubmit = async (values, { setSubmitting }) => {
    const {
      data: { status },
    } = await api.fetch.post(verifyTwoFactorUrl, {
      email: accountEmail,
      code: values.code,
    });
    if (status === 'Success') {
      window.location.href = redirectURL;
    }
    setSubmitting(false);
  };

  return (
    <div className={`login-view--${APP_ID}`}>
      <section className="login-box">
        <div className="pe-logo">
          <a href="/" rel="Home">
            <img
              className="wl-logo"
              src={PRODUCT_IMAGE[APP_ID].loginLogo}
              alt="PickExp"
            />
          </a>
        </div>

        {!useTwoFactor && (
          <div className="form-box">
            {isLogIn && (
              <Formik initialValues={initialLoginValues} onSubmit={onSubmit}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  submitForm,
                }) => (
                  <form className="login-form" name="pickexp-login">
                    <h2 className="box-title">Log In</h2>
                    <div className="log-input">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={values.username}
                        name="username"
                        placeholder="Username"
                        require=""
                      />
                    </div>
                    <div className="log-input">
                      <input
                        type="password"
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        placeholder="Password"
                        require=""
                      />
                    </div>
                    <button
                      type="button"
                      className="pe-button-white"
                      disabled={
                        isSubmitting || !(values.username && values.password)
                      }
                      onClick={submitForm}
                    >
                      Log In
                    </button>
                  </form>
                )}
              </Formik>
            )}

            {isSignUp && (
              <Formik
                initialValues={initialSignUpValues}
                onSubmit={onSubmit}
                validate={validateSignUp}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  submitForm,
                }) => (
                  <form className="signup-form" name="pickexp-signup">
                    <div className="signup-data">
                      <h2 className="box-title">Sign Up</h2>
                      <div className="signup-input active">
                        <div className="signup-names">
                          <input
                            type="text"
                            name="first_name"
                            onChange={handleChange}
                            value={values.first_name}
                            placeholder="Name"
                            require=""
                          />
                          <input
                            type="text"
                            onChange={handleChange}
                            value={values.last_name}
                            name="last_name"
                            placeholder="Last Name"
                          />
                        </div>
                        <input
                          type="email"
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          placeholder="Email"
                          require=""
                        />
                        <input
                          type="password"
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          placeholder="Password"
                          require=""
                        />
                        <div className="sign-button">
                          <button
                            type="button"
                            className="pe-button-white"
                            disabled={
                              isSubmitting ||
                              !(
                                values.first_name &&
                                values.email &&
                                values.password
                              )
                            }
                            onClick={submitForm}
                          >
                            Sign up
                          </button>
                        </div>
                      </div>

                      <div className="msg--error">
                        {errors.email && touched.email && errors.email}
                        {errorMessage}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        )}

        {useTwoFactor && (
          <Formik
            initialValues={{ code: [] }}
            onSubmit={onTwoFactorCodeSubmit}
            validate={() => {}}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              submitForm,
            }) => {
              const onKeyUpHandler = (v) => {
                values.code.length === 4 && submitForm();
              };
              return (
                <div className="two-factor-code-form-box">
                  <form
                    className="two-factor-code-form"
                    name="pickexp-two-factor-code"
                  >
                    <input
                      type="number"
                      min="0"
                      max="9"
                      onChange={handleChange}
                      onKeyUp={onKeyUpHandler}
                      name="code[0]"
                      placeholder="0"
                      require=""
                    />
                    <input
                      type="number"
                      min="0"
                      max="9"
                      onChange={handleChange}
                      onKeyUp={onKeyUpHandler}
                      name="code[1]"
                      placeholder="0"
                      require=""
                    />
                    <input
                      type="number"
                      min="0"
                      max="9"
                      onChange={handleChange}
                      onKeyUp={onKeyUpHandler}
                      name="code[2]"
                      placeholder="0"
                      require=""
                    />
                    <input
                      type="number"
                      min="0"
                      max="9"
                      onChange={handleChange}
                      onKeyUp={onKeyUpHandler}
                      name="code[3]"
                      placeholder="0"
                      require=""
                    />
                  </form>
                </div>
              );
            }}
          </Formik>
        )}

        {isLogIn && (
          <div className="sign-up-link">
            <p>Not a member yet? </p>{' '}
            <b
              onClick={() => {
                setIsSignUp(true);
                setIsLogIn(false);
              }}
              rel="sign up"
            >
              Sign up
            </b>
          </div>
        )}

        {isSignUp && (
          <div className="sign-in-link">
            <p>Already have an account? </p>{' '}
            <b
              onClick={() => {
                setIsSignUp(false);
                setIsLogIn(true);
              }}
              rel="Log in"
            >
              Log in
            </b>
          </div>
        )}
      </section>
    </div>
  );
};

export default LoginView;
