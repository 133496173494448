const axios = require('axios');

const PeFetch = ({ API_CONFIG }) => {
  const { APP_PLATFORM, APP_ID, PROXY_URLS } = API_CONFIG;

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      if (config.url) {
        for (let k in PROXY_URLS) {
          if (
            config.url.indexOf(k) != -1 &&
            !config.url.includes(PROXY_URLS[k])
          ) {
            config.url = `${PROXY_URLS[k]}${config.url}`;
          }
        }
      }

      const ptk = localStorage.getItem('ptk');
      if (ptk) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('ptk');
      }

      const stk = localStorage.getItem('stk');
      if (stk) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('stk');
      }

      config.headers['pe-platform'] = APP_PLATFORM;
      config.headers['pe-product'] = APP_ID;
      config.headers['pe-ctz'] = new Date().getTimezoneOffset();
      config.headers['cache-control'] = 'no-cache';

      config.timeout = 5000;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      if (response.headers['pe-token-refresh']) {
        localStorage.setItem('stk', response.headers['pe-token-refresh']);
      }
      if (response.data && response.data.data) {
        response.data = response.data.data;
      }
      return response;
    },
    function (error, d) {
      const errorMessage = error.message;
      let dataMessage;
      if (error.response.data) {
        dataMessage = error.response.data.message;
      }

      if (errorMessage === 'Network Error') {
        window.dispatchEvent(new Event('networkerror'));
      }
      if (errorMessage === 'Request failed with status code 401') {
        //window.dispatchEvent(new Event('gevent'));
        //PickExpService.logout();
      }

      return Promise.reject({
        error,
        dataMessage,
      });
    }
  );

  return {
    get(url, params, config) {
      return new Promise((resolve, reject) => {
        axios
          .get(url, params, config)
          .then(resolve)
          .catch((err) => {
            //console.log('err', err);
            reject(err);
          });
      });
    },
    patch(url, params, config) {
      return new Promise((resolve, reject) => {
        axios
          .patch(url, params, config)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            //console.log('err', err);
            reject();
          });
      });
    },
    post: async (url, params, config) => {
      const r = await axios.post(url, params, config);
      return r;
    },
    delete(url, params, config) {
      return new Promise((resolve, reject) => {
        axios
          .delete(url, params, config)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            //console.log('err', err);
            reject();
          });
      });
    },
  };
};

export default PeFetch;
