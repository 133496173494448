import React from 'react';
import './App.scss';
import LoginView from './components/login-view';
import VerifyView from './components/verify-view';
import { Route, Routes } from 'react-router-dom';
import { ROUTE } from './constants/routes';

function App() {
  return (
    <div className={`app`}>
      <div className="view-content">
        <Routes>
          <Route
            path={`${ROUTE.LOGIN}`}
            caseSensitive={false}
            element={<LoginView />}
          />
          <Route
            path={`${ROUTE.HOME}`}
            caseSensitive={false}
            element={<LoginView inSignUp />}
          />
          <Route
            path={`${ROUTE.VERIFY_EMAIL}`}
            caseSensitive={false}
            element={<VerifyView />}
          />
        </Routes>
        <div className="version">
          {`v${process.env.REACT_APP_VERSION}` || 'v0.0.0'}
        </div>
      </div>
    </div>
  );
}

export default App;
